svg {
  margin-bottom: 2px;
}

::-webkit-scrollbar {
  height: 2rem !important;
  width: 1.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor20);
  border-radius: 1.1rem;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  border: 0.5em solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.nowrap {
  white-space: nowrap;
}

.card {
  border: 2px solid var(--primaryColor);
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
  font-weight: 500;
  font-size: 1.4rem !important;
  color: var(--darkTextColor);
  background-color: var(--whiteColor);
}

.header {
  font-size: 1.4rem;
  color: var(--primaryTextColor);
  white-space: nowrap;
  font-weight: 600;
  padding: 20px 0 0 0;
}

.tableHeader {
  background: var(--primaryColor);
  color: var(--whiteTextColor);
  border-bottom: none;
  font-weight: 600;
  font-size: 1.4rem;
  overflow: hidden auto;
  width: 100% !important;
  margin: 0 !important;
  border-radius: 2rem 2rem 0 0;
  padding: 1.5rem 0 1.3rem 0;
  cursor: default;
}

.tableHeaderBanner {
  border-radius: 0 0 0 0;
}

.smallHeader {
  padding: 0.5rem 0 0.4rem 0;
}

.tableHeaderRow {
  padding: 0rem 2rem !important;
}

.rows {
  font-weight: 400;
  color: var(--darkTextColor);
  font-size: 1.4rem;
  padding: 1.8rem 2rem !important;
  border-bottom: 1px var(--darkTextColor) solid;

  a {
    color: var(--primaryTextColor) !important;
  }
}

.tableContainer {
  overflow-x: auto;
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  background-color: var(--whiteColor);
}

.rowWrapper {
  margin: 0 !important;
  overflow-wrap: break-word;
}

.button {
  padding: 0rem 1rem !important;
  border-bottom: 1px var(--darkTextColor) solid;
  text-align: left;
}

.lastUpdated {
  font-weight: 500;
  font-size: 13px;
  text-align: right;
  color: var(--darkTextColor);
  margin: 0;
}

.numRecords {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: var(--darkTextColor);
  margin: 0;
  margin-top: 20px;
}

.emptyTableText {
  margin-inline: 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: var(--primaryTextColor);
  padding: 30px;
  text-align: center;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);

  svg {
    width: 300px;
    height: 300px;
  }
}

.emptyTableData {
  margin-inline: 0;
  background: var(--whiteColor);
  border-radius: 2rem;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: var(--primarytextColor);
  padding: 30px;
  text-align: center;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);

  svg {
    // so that it is aligned with the application activity on the left
    width: 203px;
    height: 203px;
  }
}

.disabled {
  background-color: var(--blurColor);
}

.highlighted {
  font-weight: 600;
  background-color: var(--primaryColor10Solid);
}

.highlighted span {
  font-weight: 600;
  font-size: 1.2rem;
}