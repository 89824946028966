@import 'src/styles/global.scss';

.infoContainer {
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  height: 100%;
  background-color: var(--whiteColor);
}

.infoHeader {
  @extend .fontLabelM;
  background: var(--primaryColor);
  border-radius: 2rem 2rem 0 0;
  padding: 1rem 3.5rem;
  display: flex;
  justify-content: flex-start;
  gap: 2.5rem;
  align-items: center;

  img {
    width: 4.5rem;
    aspect-ratio: 1;
    object-fit: cover;
  }

  div {
    color: var(--whiteTextColor);
  }
}

.infoBody {
  padding: 3.5rem;
  background-color: var(--whiteColor);
  border-radius: 0 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  position: relative;
}

.infoRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  p {
    margin: 0;

    &:first-child {
      color: var(--primaryVariationTextColor);
    }

  }
}

.noHeader {
  border-radius: 2rem;
}

.editInfo {
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;
}

.deactivated {
  background-color: var(--blurColor);
}

@media only screen and (max-width: 990px) {
  .infoContainer {
    margin-bottom: 3.5rem;
  }
}